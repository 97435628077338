<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">通知详情</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <p>
          <span>【通知】</span>
          {{list.title}}
        </p>
        <p>发布时间：{{list.publishTime}}</p>
      </div>
      <div class="name">
        <div class="left">
          <span>发布人：{{list.manageName}}</span>
        </div>
        <div class="right" @click="toUnRead" v-show="this.type==='manager'">
          <span>已读 ({{msgReadNum}})</span>
          <span>|</span>
          <span>未读 ({{msgUnReadNum}})</span>
          <!-- <span>已读 ({{list.eadNum}})</span>
          <span>|</span>
          <span>未读 ({{list.allNum}})</span>-->
        </div>
      </div>
      <div class="text" style="white-space: pre-line;" v-html="list.content"></div>
      <ul>
        <li v-for="(item,index) in list.imgs" :key="index">
          <el-image
            class="up-load"
            :src="`${apiUrl}/image?name=${item}`"
            :preview-src-list="[`${apiUrl}/image?name=${item}`]"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { getMsgReadAPI, userReadAPI } from "@/api/inform";
export default {
  data() {
    return {
      list: {},
      msgId: "",
      isShow: false,
      status: 0,
      msgReadNum: 0,
      msgUnReadNum: 0,
      apiUrl: process.env.VUE_APP_BASE_API,
      cancel,
      type: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.list = JSON.parse(this.$route.query.item);
    this.status = this.list.status;
    this.msgId = JSON.parse(this.$route.query.item).id;
    this.getReadFn();
    this.isShowFn();
    if (this.msgId) {
      this.userReadFn();
    }
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    async getReadFn() {
      const res = await getMsgReadAPI(this.msgId);
      if (res.code === 0) {
        res.data.forEach((item) => {
          this.msgReadNum += item.msgReadNum;
          this.msgUnReadNum += item.msgUnReadNum;
        });
      }
    },
    toUnRead() {
      this.$router.push({
        path: "inform-unread",
        query: { id: this.list.id },
      });
    },
    isShowFn() {
      const user = JSON.parse(sessionStorage.getItem("personInfo"));
      if (!user.isClassManager && !user.isManager) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    userReadFn() {
      if (this.type !== "manager") {
        userReadAPI(this.msgId).then((res) => {
          console.log(res);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;
    z-index: 99;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .content {
    // background: #ffffff;
    padding-top: 4rem;
    // height: 95vh;
    width: 90%;
    padding: 4rem 5% 0 5%;
    margin: 0 auto;
    .title {
      p {
        span {
          font-weight: 700;
        }
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        text-indent: 0.5rem;
        color: #bbb;
      }
    }
    .name {
      text-indent: 0.5rem;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #aaaaaa;
      }
      .left {
        span:first-child {
          margin-right: 0.5rem;
        }
      }
      .right {
        span {
          font-weight: 700;
        }
        span:nth-child(2) {
          margin: 0 0.3rem;
        }
        span:nth-child(3) {
          color: skyblue;
        }
      }
    }
    .text {
      width: 100%;
      margin: 2rem auto;
      // margin-top: 2rem;
      textarea {
        width: 100%;
        height: 70vh;
        border: none;
        resize: none;
      }
    }
    ul {
      width: 90%;
      margin: 0 auto;
      li {
        width: 50%;
        margin: 0 auto;
        img {
          height: 100%;
        }
      }
    }
  }
}
</style>